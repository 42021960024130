import React from "react"
import Layout from "../components/layout"
import styles from "./contact.module.css"
import SEO from "../components/seo"

const Contact = () => {
  const textStripContent = [
    <div className={styles.textStripItem} key="1">
      Work with us.
    </div>,
  ]

  const textStripContainer = (
    <div className={styles.textStripContainer}>
      {textStripContent} {textStripContent}
    </div>
  )

  return (
    <Layout>
      <SEO title="Contact" />
      <div className={styles.wrapper}>
        <div className={styles.textStrip}>
          <div className={styles.textStripSlider}>
            {textStripContainer}
            {textStripContainer}
          </div>
        </div>

        <div className={styles.listContainer}>
          <div>
            {/* <h2 className={styles.title}>
              {"Let's make "}
              it <span>real.</span>
            </h2> */}
            <p className={styles.blurb}>
              We believe in the power of digital and love collaborating with
              brands that feel the same. Let's connect.
            </p>
          </div>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            hidden="true"
            className={styles.contactForm}
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />

            {/* <label for="enquiry">What can we help with?</label>
            <select id="enquiry" name="enquiry">
              <option value="">Choose one...</option>
              <option value="prototype">
                I’d like help creating an interactive prototype
              </option>
              <option value="mvp">I’d like help building an MVP</option>
              <option value="existing product">
                I want to improve an existing online product
              </option>
              <option value="ui/ux">I’d like some UI/UX design help</option>
              <option value="shopify">
                I want to create/improve my online store (shopify)
              </option>
              <option value="not sure">Not sure yet</option>
            </select> */}

            {/* <label for="name"> Tell us a bit about yourself</label> */}
            <div className={styles.inputBoxes}>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                required
              />

              <input type="email" name="email" placeholder="Email" required />
            </div>
            {/* <label for="budget">Whats your budget?</label>
            <select id="budget" name="budget">
              <option value="0">I've got...</option>
              <option value="1">nothing</option>
              <option value="2">something</option>
              <option value="3">a lot!</option>
              <option value="4">it doesn't matter at this stage</option>
            </select> */}
            {/* <label for="explanation"> Explain a bit about your project</label> */}
            <textarea
              className={styles.lastInput}
              type="text"
              name="explanation"
              id="explanation"
              placeholder="Explain a bit about your project. Protip: Touch base on idea, timeline and budget"
              rows="10"
            />
            <div className={styles.buttonHouse}>
              <button type="submit">Send</button>
            </div>
          </form>
        </div>
        <div className={styles.office}>
          <div className={styles.officeContent}>
            <h3>
              Find us in <span>London</span>
              <svg
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1713_1873)">
                  <path
                    d="M27.5749 20.6954L30.4926 12.679C30.9357 11.4616 30.3075 10.1144 29.09 9.67126C27.8725 9.22813 26.5267 9.85682 26.0836 11.0743L23.6768 17.6871"
                    stroke="white"
                    stroke-width="1.79326"
                    stroke-linecap="round"
                  />
                  <path
                    d="M28.9027 26.3982L35.4902 17.5611C35.6731 17.3161 35.8055 17.0373 35.8797 16.7407C35.9538 16.4441 35.9683 16.1357 35.9221 15.8334C35.876 15.5312 35.7702 15.2412 35.611 14.9802C35.4517 14.7192 35.2422 14.4925 34.9945 14.3133V14.3133C34.7482 14.135 34.4692 14.0071 34.1733 13.937C33.8775 13.8669 33.5707 13.8559 33.2706 13.9048C32.9705 13.9536 32.683 14.0613 32.4247 14.2216C32.1663 14.3819 31.9422 14.5917 31.7652 14.8389L27.5738 20.6937M18.7537 17.1411L19.0313 20.8688C19.0615 21.4802 18.8515 22.079 18.4463 22.5378C18.0412 22.9965 17.4728 23.2788 16.8624 23.3244C16.2521 23.37 15.648 23.1754 15.1791 22.782C14.7103 22.3886 14.4136 21.8276 14.3525 21.2186L14.0722 17.4899C14.0493 17.1825 14.0871 16.8736 14.1836 16.5808C14.2801 16.2881 14.4333 16.0172 14.6345 15.7836C15.0407 15.3119 15.6178 15.021 16.2386 14.9747C16.8594 14.9285 17.4731 15.1307 17.9448 15.537C18.4165 15.9433 18.7075 16.5203 18.7537 17.1411V17.1411Z"
                    stroke="white"
                    stroke-width="1.79326"
                    stroke-linecap="round"
                  />
                  <path
                    d="M22.8634 23.3013C23.4256 22.6268 23.7033 21.76 23.6377 20.8844L23.3549 17.0944C23.3323 16.7916 23.25 16.4963 23.113 16.2254C22.9759 15.9545 22.7867 15.7133 22.5562 15.5157C22.3258 15.3181 22.0585 15.168 21.7698 15.0739C21.4812 14.9799 21.1768 14.9437 20.8741 14.9676V14.9676C20.5667 14.9919 20.2672 15.0777 19.9935 15.2198C19.7197 15.3619 19.4773 15.5575 19.2805 15.795C19.0837 16.0325 18.9365 16.3071 18.8477 16.6024C18.7589 16.8978 18.7303 17.208 18.7635 17.5147L18.8107 17.9594"
                    stroke="white"
                    stroke-width="1.79326"
                    stroke-linecap="round"
                  />
                  <path
                    d="M23.0902 31.7745L24.2532 30.6734C24.2805 30.6476 24.3018 30.6162 24.3157 30.5812C24.3295 30.5463 24.3354 30.5087 24.3331 30.4712C24.3308 30.4337 24.3203 30.3972 24.3023 30.3642C24.2842 30.3312 24.2592 30.3026 24.2289 30.2804L19.8924 27.0847C19.4711 26.7742 19.1719 26.3258 19.0469 25.8175C18.922 25.3093 18.9792 24.7733 19.2085 24.3028V24.3028C19.4665 23.7738 19.9225 23.3677 20.4778 23.1725C21.0331 22.9774 21.643 23.0089 22.1752 23.2602L28.503 26.2543C28.503 26.2543 33.4232 28.9529 29.721 33.5063C26.021 38.062 24.7642 39.6464 20.756 38.1876L13.9421 35.7075C12.4006 35.1465 11.145 33.996 10.4517 32.5091C9.75841 31.0223 9.68413 29.321 10.2452 27.7794V27.7794L14.1289 16.4834"
                    stroke="white"
                    stroke-width="1.79326"
                    stroke-linecap="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1713_1873">
                    <rect
                      width="34.9384"
                      height="34.9384"
                      fill="white"
                      transform="translate(12.9355 0.693359) rotate(20)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </h3>
            <p>
              We're a proudly independent studio based in London - Write, call,
              or pop by.
            </p>
            <a
              href="https://www.google.com/maps/place/This+is+Undefined,+35-37+Bow+Rd,+Bow,+London+E3+2AD/@51.5268768,-0.0278222,17z/data=!4m6!3m5!1s0x487603caca1b78bb:0xf98075c50085e1f5!8m2!3d51.5268768!4d-0.0278222!16s%2Fg%2F11fn29q3y4"
              target="_blank"
            >
              115 Coventry Road
              <br /> Bethnal Green, London
              <br /> E2 6GG
            </a>
          </div>
          <img className={styles.officeImg} src="/images/office-img.jpg" />
        </div>
      </div>
    </Layout>
  )
}

export default Contact
